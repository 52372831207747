

























import { mapGetters, mapActions } from "vuex";
import {
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
} from "bootstrap-vue";
import vSelect from "vue-select";

export default {
  components: {
    BForm,
    BRow,
    BCol,
    BButton,

    vSelect,
  },
  data() {
    return {
      userData: {
        email: "",
        password: "",
        confirmPassword: "",
        firstName: "",
        lastName: "",
        civility: 0,
        phoneNumber: "",
      },
    };
  },
  mounted() {
    this.fetchUser(this.userInfo.id).then((res) => {
      console.log("resData", res);
      this.userData = res;
    });
  },
  methods: {
    ...mapActions(["fetchUser", "updateUser"]),
    updateUserEvent() {
      let dataUpdateUser  = {
        id: this.userInfo.id,
        phoneNumber: this.userData.phoneNumber,
        civility: this.userData.civility,
        firstName: this.userData.firstName,
        lastName: this.userData.lastName,
        email: this.userData.email,
      };
      this.updateUser(dataUpdateUser);
    },
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
};
